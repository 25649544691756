import React from 'react';
import "./service_banner.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import image from "../assests/e-commerce.jpg";
import appdev from "../assests/Appdev.jpeg";
import qatest from "../assests/QandA.jpg";
import automate from "../assests/Automation.jpeg";
import web from "../assests/Webservice.jpeg";
import inter from "../assests/iot.jpg";
import Banner from '../components/banner';


const Service_banner = () => {
    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4,
          slidesToSlide: 4// optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1,
          slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          slidesToSlide: 1 // optional, default to 1.
        }
      };
      
  return (
    <>
    <div className='services-container' id='service'>
        <div className="row-service">
        <div className='col-md-12'>
            <h2 className='hp-title-service'style={{fontWeight:"600"}}>SERVICES</h2>
          </div>
          </div>
        <div className='cards-banner'>
        <Carousel
          responsive={responsive}
          autoPlay={true}
          autoPlaySpeed={3000}
          infinite={true}
          >
    <div className='element'>
    <h3>Ecommerce</h3>
    <div className='maincontainer'>
        <div className='thecard'>
            <div className='thefront'>
                <img src={image} alt='image' />
            </div>
            <div className='theback'>
                <li>Customization</li>
                <li>B2C Commerce</li>
                <li>B2B Commerce</li>
                <li>Omnichannel</li>
            </div>
        </div>
    </div>
    </div>
    <div className='element'>
    <h3>Mobile App Development</h3>
    <div className='maincontainer'>
        <div className='thecard'>
            <div className='thefront'><img src={appdev} alt='image' />
            </div>
            <div className='theback'>
            <li>Android</li>
                <li>Hybrid</li>
                <li>iOS</li>
                <li>PWAs</li>
            </div>
        </div>
    </div>
    </div>
    <div className='element'>
    <h3>QA & Testing</h3>
    <div className='maincontainer'>
        <div className='thecard'>
            <div className='thefront'> <img src={qatest} alt='image' />
            </div>
            <div className='theback'>
            <li>Manual Testing</li>
               <li>Functional & Non-Functional</li>
               <li>Automation Testing</li>
               <li>VAPT</li>
            </div>
        </div>
    </div>
    </div>
    <div className='element'>
    <h3>Automation</h3>
    <div className='maincontainer'>
        <div className='thecard'>
            <div className='thefront'><img src={automate} alt='iamge' />
            </div>
            <div className='theback'>
            <li>Process Automation</li>
                <li>Intelligent Automation</li>
                <li>HR Automation</li>
                <li>Sales & Marketing </li>
            </div>
        </div>
    </div>
    </div>
    <div className='element'>
    <h3>Custom & Web Solution</h3>
    <div className='maincontainer'>
        <div className='thecard'>
            <div className='thefront'> <img src={web} alt='iamge' />
            </div>
            <div className='theback'>
                <li>ERP Software</li>
                <li>Website Development</li>
                <li>CRM Software</li>
                <li>PWAs</li></div>
        </div>
    </div>
    </div>
    <div className='element'>
    <h3>Internet Of Things</h3>
    <div className='maincontainer'>
        <div className='thecard'>
            <div className='thefront'><img src={inter} alt='iamge' />
            </div>
            <div className='theback'>
            <li>Industrial IoT</li>
            <li>IoT Integration</li>
            <li>Connected Tech</li>
            <li>Facility Monitoring</li>
            </div>
        </div>
        </div>
    </div>
</Carousel>
</div>
<Banner />
    </div>
    
    </>
    
  )
}

export default Service_banner;


