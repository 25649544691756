import React, { useState, useEffect } from 'react';
import "../components/Contactus.css";
import { useForm, ValidationError } from '@formspree/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Submit from '../assests/Submit.jpg';

const Contactus = () => {
  const [state, handleSubmit] = useForm("xvoekrbg");
  const [showModal, setShowModal] = useState(false);

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (state.succeeded) {
      setShowModal(true);
    }
  }, [state.succeeded]);

  return (
    <div className='Contactus' id='contact'>
      <h2 className='hp-title' style={{color:"#fff",width:"100%",textAlign:"center", lineHeight:"2rem",padding:"0rem"}}>Empower your business with 9dotss - where technology meets excellence</h2>
      <p className='subhead'style={{color:"#fff",padding:"0 3rem"}}>Ready to elevate your business with innovative softech solutions? Take the first step towards success by reaching out to us.</p>
      <div className='form-box'>
        <form onSubmit={handleSubmit}>
          <input type="text" name='name' placeholder='Name' autoComplete='off' required/>
          <input type='number' name='number' placeholder='Number'autoComplete='off' required/>
          <input type="email" name='email' placeholder='Email' autoComplete='off' required/>
          <button className='submit' type='submit'>Connect now</button>
        </form>
      </div>
      {showModal && (
        <div className={`modal ${showModal ? 'show' : ''}`}>
          <div className="modal-content">
            <img src={Submit}/>
            <h5>Thank You</h5>
            <p>Your Response has been submitted</p>
            <button className="close-modal" onClick={closeModal}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default Contactus;