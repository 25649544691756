import React from "react";
import "./index.css";
import Navbar from '../src/components/Navbar';
import HeroImg from '../src/components/backgroundImg';
import Footer from '../src/components/Footer';
import Cards from '../src/components/Cards';
import Statics from '../src/components/statics_banner';
import SerBanner from '../src/components/Service_banner';
import Banner from '../src/components/banner';
import Review from '../src/components/review';
import Particlebg from '../src/components/particlebg';
import Recentwork from '../src/components/recentwork';
import Contactus from "./components/Contactus";
import Testimonials from "./components/Testimonials";
import Gototop from "./components/Gototop";


function App() {
  return (
    <div>
      <Navbar /> 
      <Particlebg />
       <HeroImg /> 
       <Statics />
       <Cards />
      <SerBanner />
      {/* < Banner /> */}
      <Recentwork /> 
      <Testimonials/>
      <Contactus />
      <Gototop/>
      <Footer /> 
    </div>
  );
}

export default App;
