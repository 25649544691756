import "./Cards.css";
import vision from '../assests/OURVISION.png';
import mission from '../assests/OURMISSION.png';
import experience from '../assests/OUREXPERIENCE.png';

import React from 'react';

const Cards = () => {
    return (
        <div className="card" id="cards">
            <div className="card-container" style={{display:"flex", flexDirection:"column", padding:"2rem 200px",paddingTop:"3rem"}}>
            <h2 className='hp-title'>ABOUT US</h2>
            <div className="aboutus" >
                <div className="aboutcontent">
                <p style={{fontSize:"1.3rem"}}>
                Welcome to 9 Dotss, where we are at the forefront of the softech solutions industry. Based in Gurgaon, we are an agile and innovative team, ceaselessly committed to the pursuit of excellence. Our journey commenced in 2020, ignited by a vision to redefine the industrial landscape through innovative software and tech solutions that empower businesses and individuals to thrive in the digital era.

                </p>
                </div>
                
            </div>
            </div>
            <div className="card-container" >
            <div className="card-container-vision" style={{padding:"0 160px",background:"#fff"}}>
            <div className="card-image">
                <img src={vision} style={{width:"35rem", height:"26rem"}} />
                </div>
                <div className="card-header">
                 <div className="card-heading">OUR VISION</div>
                 <span className="bar"></span>
                 <div className="card-sub">To redefine the industrial landscape through innovative tech solutions and services with future-ready technology to drive customer success </div>
                 </div>
                 </div>
            </div>
            <div className="card-container" style={{backgroundColor:"#f8f8f8"}}>
            <div style={{display:"flex",alignItems:"center",padding:"0 150px",paddingLeft:"200px"}}>
                <div className="card-header">
                 <div className="card-heading">OUR MISSION</div>
                 <span className="bar"></span>
                 <div className="card-sub">Our mission is to enhance customer business value through the creation of efficient, agile solutions and services, guided by a customer-first approach. We strive to enable and establish robust, trusted partnerships that foster growth and embrace emerging technologies</div>
                 </div>
                 <div className="card-image" style={{paddingLeft:"3.2rem"}}>
                    <img src={mission} />
                 </div>
                 </div>
            </div>
            <div className="card-container">
            <div style={{display:"flex",alignItems:"center",padding:"0 150px",background:"#fff"}}>
            <div className="card-image">
                    <img src={experience} style={{width:"34rem",height:"27rem"}}/>
                 </div>
                <div className="card-header">
                 <div className="card-heading">OUR EXPERTISE</div>
                 <span className="bar"></span>
                 <div className="card-sub">We cater to a diverse clientele spanning industries like Telecom, Infrastructure, Healthcare, Educational Institutions, Corporate, Heavy Industries, and more. We wholeheartedly invest in extensive research and development, conceiving novel solutions that anticipate and address the ever-evolving needs of businesses, rendering them future-proof, sustainable, and consistently high-performing</div>
                 </div>
                 </div>
            </div>
        </div>
    )
}

export default Cards
