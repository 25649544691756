import "./NavbarStyle.css";
import { FaBars, FaTimes } from "react-icons/fa"
import Logo from "../assests/BrandLogo9Dots.png.png";
import { Link } from "react-scroll";

import React, { useState } from 'react';

const Navbar = () => {
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);

    const [color, setColor] = useState(false);
    const [logoSize, setLogoSize] = useState({ width: '23rem', height: '15rem' });
    const changeColor = () => {
        if (window.scrollY >= 1) {
            setColor(true);
            setLogoSize({ width: '8rem', height: '5rem' });
            document.getElementById("logo").style.marginTop = "0rem";
        } else {
            setColor(false);
            setLogoSize({ width: '23rem', height: '15rem' });
            document.getElementById("logo").style.marginTop = "4rem";
        }
    };
    window.addEventListener("scroll", changeColor);
    return (

        <div className={color ? "header header-bg" : "header"}>
            <div className="logo-dot">
                <img src={Logo} alt="Logo" className="logo" id="logo" style={logoSize} />

            </div>



            <ul className={click ? "nav-menu active" : "nav-menu"}>

                <li>
                    <Link to="img" spy={true} smooth={true} offset={-100} duration={500}>Home</Link>
                </li>
                <li>
                    <Link to="cards" spy={true} smooth={true} offset={-80} duration={500}>About Us</Link>
                </li>
                {/* <li>
                    <Link to="/" spy={true} smooth={true} offset={-100} duration={500}>Organisation</Link>
                </li> */}
                <li>
                    <Link to="service" spy={true} smooth={true} offset={-50} duration={500}>Services</Link>
                </li>
                <li>
                    <Link to="review" spy={true} smooth={true} offset={-57} duration={500}>Success Stories</Link>
                </li>
                <li id="contact-us">
                    <Link to="contact" spy={true} smooth={true} offset={-50} duration={500}>Contact Us</Link>
                </li>
            </ul>
            <div className="hamburger" onClick={handleClick}>
                {click ? (<FaTimes size={25} style={{ color: "#151513" }} />
                ) : (<FaBars size={25} style={{ color: "#151513" }} />
                )}
            </div>

        </div>
    )
}

export default Navbar;
