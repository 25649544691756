import React from 'react'
import "./FooterStyles.css";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from "react-scroll";
const Footer = () => {
  return (
    <div className="footer">
      <div className='footer-container'>
        <div className='Logo-section'>
            <div className='logo-sub-head'>
                <p>Transforming business into futuristic brands through our innovative softech solutions and services</p>
            </div>
            <button className='logo-btn'><Link to="cards" spy={true} smooth={true} offset={-80} duration={500}style={{color:"#fff"}}>Read More</Link></button>
            <div className='icons'>
                <div className='icon-box'>
             <FacebookIcon sx={{ fontSize: 30, color: '#fff' }} />
              </div>
             <div className='icon-box'>
            <InstagramIcon sx={{ fontSize: 30, color: '#fff' }} />
             </div>
            <div className='icon-box'>
           <LinkedInIcon sx={{ fontSize: 30, color: '#fff' }} />
             </div>
             <div className='icon-box'>
           <MailOutlineIcon sx={{ fontSize: 30, color: '#fff' }} />
             </div>


        </div>
        </div>
        {/* <div className='useful-links'>
              <h2 className='use-head'>USEFUL LINKS</h2>
              <ul className='use-list'>
                <li>HOME</li>
                <li>ABOUT US</li>
                <li>SERVICE</li>
                <li>CONTACT</li>
                <li>CAREER</li>
                <li>SUCCESS STORIES</li>
                </ul>
        </div> */}
        <div className='our-services'>
        <h2 className='service-head'style={{fontSize:"1.2rem",color:"#fff"}}>OUR SERVIES</h2>
              <ul className='service-list'>
              <li>ECOMMERCE</li>
              <li>MOBILE APPS</li>
              <li>QA & TESTING</li>
              <li>AUTOMATION</li>
              <li>CUSTOM & WEB SOLUTIONS</li>
              <li>IOT SOLUTIONS</li>
              </ul>
        </div>
        <div className='contact'>
        <div className='addresss'>
                <h2 style={{fontSize:"1.2rem",marginBottom:"20px",color:"#fff"}}>OUR CONTACT DETAILS</h2>
                <span><strong>Address: </strong>SIPL Building, Plot no. B1, Info Technology Park, Sector 34, Gurugram, Haryana 122004</span>
                </div>
        <div className='phone-number'>
             <a href="tel:0124-422-2616"  style={{color:"#fff"}}>
              <strong>Phone:</strong> 0124-422-2616
              </a>
                </div>
                <div className='phone-number'>
                <strong>Email:</strong> info@9dotss.com 
                </div>      
          
    </div>
    <div className='location'>
      <h1>OUR LOCATION</h1>
      <div className='map'>
      <div><iframe width="260" height="210" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=%20SUBHASH%20INFRAENGINEERS%20PRIVATE%20LIMITED,%20Plot%20no.%20B1,%20Info%20Technology%20Park,%20Sector%2034,%20Gurugram,%20Haryana%20122004Dublin,%20Ireland+(9%20Dotss)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/">gps devices</a></iframe></div>
      </div>

    </div>
    </div>
    <span className="bar" style={{width:"100%", backgroundColor:"#fff"}}></span>
    <p className='copyright'>&copy; 2024 </p>
    </div>
    
  )
}

export default Footer