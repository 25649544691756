import React from 'react'
import "./recentwork.css";
import laptop from "../assests/slide-right.png";
import eduwiz from "../assests/Eduwis.png";

const recentwork = () => {
  return (
    <div className='recentwork'>
        <div className='upper-div'>
            <div className='header-work'>
                OUR RECENT PROJECT
            </div>
            <div className='sub-work'>
                <div className='subhead-work'><span style={{color:'#0056b3'}}>Eduwis</span> School ERP - 100% Secure & Encrypted</div>
            </div>
        </div>
        <img src={eduwiz} style={{position:"absolute",left:"30%",height:"4rem",marginTop:"11rem"}}/>
        <img src={laptop} style={{height:"25rem", width:"28rem"}} />
        <div className='lower-div'>
            <div className='lower-header'>Eduwis, developed by 9dotss, offers a suite of powerful features tailored for seamless school administration, engaging teaching, and empowered learning. 
</div>
            <div className='lower-subhead'>Join us on this journey towards a smarter, more connected future in education
            <a href='http://www.eduwis.org/'><button className='cta'>Know More</button></a>
            </div>

        </div>
    </div>
  )
}

export default recentwork