import React from 'react';
import "./banner.css";
import heathcarebg from '../assests/2150780304.jpg';
import retail from '../assests/retail.png';
import retailbg from '../assests/28165.jpg';
import healthcare from '../assests/healthcare.png';
import automotive from '../assests/automotive.png';
import automotivebg from '../assests/3842.jpg';
import fintech from '../assests/fintech.png';
import EngineeringIcon from '@mui/icons-material/Engineering';
import fintechbg from '../assests/177065.jpg';
import estate from '../assests/real estate.png';
import estatebg from '../assests/2151064988.jpg';
import travel from '../assests/travel.png';
import travelbg from '../assests/2150537237.jpg';
import education from '../assests/education.png';
import educationbg from '../assests/2150931132.jpg';
import entertainment from '../assests/entertaintment.png';
import entertainmentbg from '../assests/2150903389.jpg';
import { Agriculture } from '@mui/icons-material';
import agriculture from "../assests/agriculture.jpg";
import infrastructure from "../assests/infrastructure.jpeg";




const banner = () => {
  
  return (
    <div className='banner'>
      <div className='banner-container'>
        <div className="row position-relative" style={{padding:"1rem"}}>
          <div className='col-md-12'>
          <h2 className='hp-title' style={{paddingTop:"2rem"}}>INDUSTRIES WE CATER TO</h2>
            <p className='subhead'>Our custom mobile app development services have covered virtually every major industry — from automotive to healthcare and from education to fintech, and beyond.</p>
          </div>

        </div>
        <div className='row'>
          <div className='grid-container'>
          <div className='title-card'>
            <div className='title-card-bg'>
            <img src={agriculture} style={{height: '280px', width: '286px'}}/>
            </div>
            <div className='title-card-body'>
              <h1 className='card-title'>Agriculture</h1>
              {/* <p className='card-sub-title'>Sub heading</p>
              <p className='card-info'>Agriculture</p> */}
              {/* <button className='card-btn'>Explore more</button> */}

            </div>
            <div className='title-card-icon'>
            <Agriculture style={{height: '23px', width: '20px',color:"#fff"}}/>
            </div>
            <div className='title-card-title'>
              <span>Agriculture</span>
            </div>
          </div>
          <div className='title-card'>
            <div className='title-card-bg'>
            <img src={heathcarebg} style={{height: '280px', width: '286px'}}/>
            </div>
            <div className='title-card-body'>
              <h1 className='card-title'>Healthcare</h1>
              {/* <p className='card-sub-title'>Sub heading</p>
              <p className='card-info'>Desprition about Healthcare</p>
              <button className='card-btn'>Explore more</button> */}

            </div>
            <div className='title-card-icon'>
            <img src={healthcare} style={{height: '20px', width: '20px'}}/>
            </div>
            <div className='title-card-title'>
              <span>Healthcare</span>
            </div>
          </div>
          <div className='title-card'>
            <div className='title-card-bg'>
            <img src={educationbg} style={{height: '280px', width: '286px'}}/>
            </div>
            <div className='title-card-body'>
              <h1 className='card-title'>Education</h1>
              {/* <p className='card-sub-title'>Sub heading</p>
              <p className='card-info'>Desprition about Automotive</p>
              <button className='card-btn'>Explore more</button> */}

            </div>
            <div className='title-card-icon'>
            <img src={education} style={{height: '20px', width: '30px',padding:"0px"}}/>
            </div>
            <div className='title-card-title'>
              <span>Education</span>
            </div>
          </div>
          <div className='title-card'>
            <div className='title-card-bg'>
            <img src={infrastructure} style={{height: '280px', width: '286px'}}/>
            </div>
            <div className='title-card-body'>
              <h1 className='card-title'>Infrastructure</h1>
              {/* <p className='card-sub-title'>Sub heading</p>
              <p className='card-info'>Desprition about Fintech</p>
              <button className='card-btn'>Explore more</button> */}

            </div>
            <div className='title-card-icon'>
            <EngineeringIcon style={{height: '20px', width: '20px',color:"#fff"}}/>
            </div>
            <div className='title-card-title'>
              <span>Infrastructure</span>
            </div>
          </div>
          {/* <div className='title-card'>
            <div className='title-card-bg'>
            <img src={estatebg} style={{height: '280px', width: '286px'}}/>
            </div>
            <div className='title-card-body'>
              <h1 className='card-title'>Real Estate</h1>
              <p className='card-sub-title'>Sub heading</p>
              <p className='card-info'>Desprition about Real Estate</p>
              <button className='card-btn'>Explore more</button>

            </div>
            <div className='title-card-icon'>
            <img src={estate} style={{height: '20px', width: '22px'}}/>
            </div>
            <div className='title-card-title'>
              <span>Real Estate</span>
            </div>
          </div> */}
          {/* <div className='title-card'>
            <div className='title-card-bg'>
            <img src={travelbg} style={{height: '280px', width: '286px'}}/>
            </div>
            <div className='title-card-body'>
              <h1 className='card-title'>Tarvel</h1>
              <p className='card-sub-title'>Sub heading</p>
              <p className='card-info'>Desprition about Tarvel</p>
              <button className='card-btn'>Explore more</button>

            </div>
            <div className='title-card-icon'>
            <img src={travel} style={{height: '20px', width: '30px'}}/>
            </div>
            <div className='title-card-title'>
              <span>Tarvel</span>
            </div>
          </div> */}
          {/* <div className='title-card'>
            <div className='title-card-bg'>
            <img src={educationbg} style={{height: '280px', width: '286px'}}/>
            </div>
            <div className='title-card-body'>
              <h1 className='card-title'>Education</h1>
              <p className='card-sub-title'>Sub heading</p>
              <p className='card-info'>Desprition about Education</p>
              <button className='card-btn'>Explore more</button>

            </div>
            <div className='title-card-icon'>
            <img src={education} style={{height: '20px', width: '30px'}}/>
            </div>
            <div className='title-card-title'>
              <span>Education</span>
            </div>
          </div> */}
          {/* <div className='title-card'>
            <div className='title-card-bg'>
            <img src={entertainmentbg} style={{height: '280px', width: '286px'}}/>
            </div>
            <div className='title-card-body'>
              <h1 className='card-title'>Entertainment</h1>
              <p className='card-sub-title'>Sub heading</p>
              <p className='card-info'>Desprition about Entertainment</p>
              <button className='card-btn'>Explore more</button>

            </div>
            <div className='title-card-icon'>
            <img src={entertainment} style={{height: '20px', width: '30px'}}/>
            </div>
            <div className='title-card-title'>
              <span>Entertainment</span>
            </div>
          </div> */}
          
        </div>
        </div>

      </div>

    </div>
    
  )
}

export default banner;

