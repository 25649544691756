import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import test1  from "../assests/arjunsaini1.jpg";
import test2  from "../assests/monica1.jpeg";
import test3  from "../assests/vikas1.JPG";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import "../components/Testimonial.css";
const Testimonials = () => {
  return (
    <div className='Testimonials' id='review'>
        <h2 className='hp-title' style={{marginBottom:"3rem",fontWeight:"600"}}>TESTIMONIALS</h2>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        // navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
            <div className='review-section'>
            <div className='review-client-data'>
                <img src={test1} alt="" />
              <div className='client-data-details'>
                <p style={{fontSize:"1.2rem", fontWeight:"700", color:"#0056b3"}}>Arjun Saini, <span style={{fontSize:"1.1rem", fontWeight:"500", color:"#151513"}}>Director</span></p>
                <p style={{fontSize:"1rem", color:"#666"}}>Mount Olympus Group of Schools</p>
              </div>
            </div>
            <div className='review-message'>
            <FontAwesomeIcon icon={faQuoteLeft} />
              <p>           
            Eduwis has been a game-changer for our schools. I've witnessed firsthand the transformative impact of this ERP solution. From seamless administration to enriched learning experiences, this product by 9 dots has exceeded our expectations. The team at 9dotss has been exceptional in their support, making the implementation process smooth and efficient. Our school's journey with Eduwis has been nothing short of remarkable, and I highly recommend it to every educational institution seeking innovation and excellence.
            </p>
            <FontAwesomeIcon icon={faQuoteRight} />
            </div>
            </div>
            </SwiperSlide>
        <SwiperSlide>
        <div className='review-section'>
        <div className='review-client-data'>
                <img src={test2} alt="" style={{width:"6.2rem",height:"6.2rem"}} />
              <div className='client-data-details'>
                <p style={{fontSize:"1.2rem", fontWeight:"700",color:"#0056b3"}}>Monieka Khanna, <span style={{fontSize:"1.1rem", fontWeight:"500", color:"#151513"}}>CEO</span></p>
                <p style={{fontSize:"1rem", color:"#666"}}>Mount Olympus Group of Schools</p>
              </div>
            </div>
            <div className='review-message'>
            <FontAwesomeIcon icon={faQuoteLeft} />
              <p>           
              Eduwis by 9 Dotss has significantly elevated the administrative efficiency at all our schools. Having a robust ERP solution like Eduwis has streamlined our day-to-day operations. From student information management to inventory control, Eduwis has simplified complex administrative tasks. The user-friendly interface and prompt support from the 9dotss team have made Eduwis an invaluable asset for our group. I highly appreciate the impact it has had on enhancing our administrative processes and recommend it wholeheartedly to fellow educators.
            </p>
            <FontAwesomeIcon icon={faQuoteRight} />
            </div>
            
            </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className='review-section'>
        <div className='review-client-data'>
                <img src={test3} alt="" style={{height:"6.4rem",width:"6.5rem"}}/>
              <div className='client-data-details'>
                <p style={{fontSize:"1.2rem", fontWeight:"700",width:"320px",color:"#0056b3"}}>Vikas Saini, <span style={{fontSize:"1.1rem", fontWeight:"500", color:"#151513"}}>Head-Procurement</span></p>
                <p style={{fontSize:"1rem", color:"#666"}}>Subhash Infraengineers Pvt Ltd</p>
              </div>
            </div>
            <div className='review-message'>
            <FontAwesomeIcon icon={faQuoteLeft} />
              <p>           
              9 Dotss has transformed the IT infrastructure at SIPL seamlessly. Referred by a friend, we initially hesitated due to concerns about workflow disruptions and staff training. However, the transition was remarkably smooth, and 9 Dotss exceeded our expectations. Their team's excellent communication, quick service, and customized proposals made the upgrade efficient and stress-free. We appreciated their commitment to keeping us updated for future needs. We are now confidently equipped for ongoing operations and highly recommend 9 Dots for their professionalism and expertise. Thank you for the outstanding service!
            </p>
            <FontAwesomeIcon icon={faQuoteRight} />
            </div>
            
            </div>
        </SwiperSlide> 
      </Swiper>
    </div>
  )
}

export default Testimonials