import React from "react";
import { Parallax } from 'react-parallax';
import "../components/imgstyle.css";
import NorthEastSharpIcon from '@mui/icons-material/NorthEastSharp';
//import VideoBackground from "../assests/video.mp4";
//import BackgroundImg from "../assests/2150904639.jpg";
import { Link } from "react-scroll";

const HeroImg = () => {
    return (
        // <Parallax className="bgimage" bgImage={BackgroundImg} strength={300}>
            <div className="mask" id="img">
                {/* <video src={VideoBackground} autoPlay loop muted style={videoStyle}/> */}
                <div className="mask-container">
                    <h1>Welcome To <span style={{ color: "#e8282f" }}>9</span><span style={{ color: "#0056b3" }}>Dotss</span></h1>
                    <p>Transforming business into futuristic brands through our innovative softech solutions and services</p>
                    <button className="get-started-button "><Link to="cards" spy={true} smooth={true} offset={-80} duration={500}>Explore More</Link><span><NorthEastSharpIcon style={{ height: "20px" }} /></span></button>
                </div>
            </div>
        // </Parallax>
    );
}

export default HeroImg;





