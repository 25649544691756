import React, { useEffect, useRef } from 'react'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LanguageIcon from '@mui/icons-material/Language';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import './statics_banner.css';


const StaticBanner = () => {
  
  return (
    <div className='statics'>
      <div className='container'>
        <div className='row'>
          <div className='statics-content'>
            <div className='inner'>
              <span>
              <ThumbUpOffAltIcon style={{color:"#fafafa", height:"50px", width:"50px" }}/>
              </span>
              <h3>1300+</h3>
              <h4>Happy Users</h4>
            </div>
          </div>
          {/* <div className='statics-content'>
            <div className='inner'>
              <span>
                <LanguageIcon style={{color:"#fafafa", height:"50px", width:"50px" }}/>
              </span>
              <h3>50+</h3>
              <h4>Global Top Brands & Enterprises Served</h4>
            </div>
          </div> */}
          <div className='statics-content'>
            <div className='inner'>
              <span>
              <CalendarMonthIcon style={{color:"#fafafa", height:"50px", width:"50px" }}/>
              </span>
              <h3>20+</h3>
              <h4>Years Of Experience</h4>
            </div>
          </div>
          <div className='statics-content'>
            <div className='inner'>
              <span>
                <PeopleAltOutlinedIcon style={{color:"#fafafa", height:"50px", width:"50px" }}/>
              </span>
              <h3>30+</h3>
              <h4>Industry-based Tech Professionals</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StaticBanner;
