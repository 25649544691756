import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { FaArrowUp } from "react-icons/fa";

const Gototop = () => {
    const [isVisible, setIsVisible] = useState(false);
    const goToBtn = ()=>{
        window.scrollTo({top:0,left:0, behavior:"smooth"});
    };
    const listenToScroll = () => {
        let heightToHidden = 20;
        const winScroll =
          document.body.scrollTop || document.documentElement.scrollTop;
    
        if (winScroll > heightToHidden) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };
    
      useEffect(() => {
        window.addEventListener("scroll", listenToScroll);
        return () => window.removeEventListener("scroll", listenToScroll);
      }, []);
  return (
    <Wrapper>
    {isVisible && (
        <div className="top-btn" onClick={goToBtn}>
          <FaArrowUp className="top-btn--icon" />
        </div>
      )}
    </Wrapper>
    );
};

const Wrapper = styled.section`
display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .top-btn {
    font-size: 1.5rem;
    width: 3rem;
    height: 3rem;
    color: #fff;
    background-color: #0056b3;
    border-radius: 50%;
    position: fixed;
    bottom: 5rem;
    right: 5rem;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &--icon {
      animation: gototop 1.2s linear infinite alternate-reverse;
    }

    @keyframes gototop {
      0% {
        transform: translateY(-0.5rem);
      }
      100% {
        transform: translateY(0.5rem);
      }
    }
  }

  @media (max-width: 600px) {
    .top-btn {
      right: 0;
      left: 40%;
    }
  }
  `;

export default Gototop;